import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components } from "react-select";

const LocationScreen = (props) => {
  const NoOptionsMessage = (props) => {
    return <components.NoOptionsMessage {...props}>Geen opties gevonden</components.NoOptionsMessage>;
  };

  return (
    <section class="main green-bg">
      <div class="dark-bg">
        <div class="container">
          <h4 class="pull-up">Wil jij ook Beter Stoken?</h4>
          <p class="description pull-up">
            Weersomstandigheden als mist en wind spelen een belangrijke rol. Toets door middel van deze tool of het weer
            in jouw regio “groen” of “oranje” is.
          </p>
        </div>
      </div>
      <div className="container">
        <div class="location-form">
          <button class="btn location-btn" onClick={props.onGeoLocation}>
            Deel mijn locatie
          </button>
          <p class="location-txt">
            We slaan je locatie niet op. Deze app registreert geen privacy gevoelige informatie
          </p>
          <div className="autofill-form">
            <GooglePlacesAutocomplete
              debounce={300}
              minLengthAutocomplete={3}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["nl"],
                },
                types: ["(regions)"],
              }}
              selectProps={{
                className: "location--field",
                placeholder: "Plaats",
                components: {
                  DropdownIndicator: null,
                  NoOptionsMessage,
                },
                onChange: props.onLocationAutoComplete,
              }}
            />
            <button class="btn">Ga</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LocationScreen;
