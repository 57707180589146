const MidSection = (props) => {
  const getBodyClasses = (variant) => {
    let bodyClass = "main ";
    if (variant === "orange") {
      bodyClass += "orange-bg";
    } else if (variant === "grey") {
      bodyClass += "grey-bg";
    } else {
      bodyClass += "green-bg";
    }
    return bodyClass;
  };

  const variant = props.variant;

  return (
    <section class={getBodyClasses(variant)}>
      <div class="dark-bg">
        <div class="container">
          {variant === "grey" && (
            <>
              <h4>Oeps!</h4>
              <p class="description">
                Helaas ontvangen we momenteel geen data van Buienradar. Probeer het later nog eens. Mocht dit probleem
                aanhouden, neem dan contact op met Stichting NHK.
              </p>
            </>
          )}

          {variant === "green" && (
            <>
              <h4>Veel stookplezier!</h4>
              <p class="description">
                De weersomstandigheden in uw regio zijn geschikt om te stoken. Stook de juiste brandstof, doe het met
                verstand. Wij wensen u veel sfeervolle momenten bij het vuur!
              </p>
            </>
          )}

          {variant === "orange" && (
            <>
              <h4>Let op!</h4>
              <p class="description">
                Voordat u het vuur aansteekt, even naar buiten kijken. Is het windstil of mistig? De rook kan in uw
                woonomgeving blijven hangen. Niet alleen hinderlijk voor uw buren, mogelijk ook niet prettig voor uzelf.
              </p>
            </>
          )}

          <div class="location-reset">
            <img src="assets/reset icon.png" />
            <p class="text" onClick={props.onReset}>
              Dit advies is voor {props.weatherReport.stationname}. Andere locatie? Klik voor reset.
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <h6>Altijd makkelijk het Stookalert checken?</h6>
        <p>
          Altijd makkelijk het Stookalert checken' may be: Zet de link naar het Stookalert in de favorieten van de
          browser of op het openingsscherm van je telefoon.
        </p>
      </div>
    </section>
  );
};

export default MidSection;
