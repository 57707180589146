import { Component } from "preact";
import { Router } from "preact-router";

import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import Header from "./Header";
import Footer from "./Footer";
import MidSection from "./MidSection";
import LocationScreen from "./LocationScreen";

class App extends Component {
  state = {
    lat: sessionStorage.getItem("lat") || "",
    lng: sessionStorage.getItem("lng") || "",
    variant: "green",
    hasLocationReport: false,
    weatherReport: null,
  };

  constructor(props) {
    super(props);
    this.handleGeoLocation = this.handleGeoLocation.bind(this);
    this.handleLocationAutoComplete = this.handleLocationAutoComplete.bind(this);
    this.handleReset = this.handleReset.bind(this);

    if (this.state.lat !== "" && this.state.lng !== "") {
      this.fetchReport(this.state.lat, this.state.lng);
    }
  }

  setGeoLocation(lat, lng) {
    sessionStorage.setItem("lat", lat);
    sessionStorage.setItem("lng", lng);
    this.setState({ lat, lng });
  }

  fetchReport(lat, lng) {
    let variant = "green";
    const reportXHR = new XMLHttpRequest();

    reportXHR.addEventListener("load", (e) => {
      try {
        const data = JSON.parse(reportXHR.response);

        if (data.visibility < 200) {
          variant = "orange";
        } else if (data.windspeed >= 2 && data.windspeed <= 8) {
          variant = "green";
        } else {
          variant = "orange";
        }
        this.setState({
          hasLocationReport: true,
          weatherReport: data,
          variant,
        });
      } catch (e) {
        this.setState({
          hasLocationReport: true,
          weatherReport: "error",
          variant: "grey",
        });
      }
    });
    reportXHR.open("GET", `/api/weather_report.php?lat=${lat}&lng=${lng}`);
    reportXHR.send();
  }

  handleReset() {
    this.setState({
      lat: "",
      lng: "",
      hasLocationReport: false,
      weatherReport: null,
    });
  }

  handleGeoLocation(e) {
    navigator.geolocation.getCurrentPosition((data) => {
      if (data && data.coords && data.coords.latitude && data.coords.longitude) {
        this.fetchReport(data.coords.latitude, data.coords.longitude);
      }
    });
  }

  handleLocationAutoComplete(locationResult) {
    const placeId = locationResult.value.place_id;
    geocodeByAddress(locationResult.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        this.setGeoLocation(lat, lng);
        this.fetchReport(lat, lng);
      });
  }

  render() {
    return (
      <div id="app">
        <div class="home-page">
          <Header />

          {this.state.hasLocationReport && (
            <div class="status">
              {(this.state.variant === "orange" || this.state.variant === "grey") && (
                <img src="assets/Warning_.png" class="status-orange" />
              )}
              {this.state.variant === "green" && <img src="assets/tick.png" class="status-green" />}
            </div>
          )}

          <Router>
            {this.state.hasLocationReport && (
              <MidSection
                path="/"
                variant={this.state.variant}
                weatherReport={this.state.weatherReport}
                onReset={this.handleReset}
              />
            )}
            {!this.state.hasLocationReport && (
              <LocationScreen
                path="/"
                weatherReport={this.state.weatherReport}
                onGeoLocation={this.handleGeoLocation}
                onLocationAutoComplete={this.handleLocationAutoComplete}
              />
            )}
          </Router>

          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
