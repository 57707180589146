const Footer = (props) => {
  return (
    <section class="footer">
      <div class="footer-container">
        <div class="logo">
          <a href="https://stichting-nhk.nl/">
            <img src="assets/NHK-logo.png" />
          </a>
        </div>
        <div class="footer-text">
          <p>
            Stookalert is een dienst van <a href="https://stichting-nhk.nl/">Stichting NHK</a> en maakt gebruik van data
            van Buienradar voor de berekening.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
