const Header = (props) => {
  return (
    <header>
      <div class="hero-img">
        <div class="hero-title">
          <img src="assets/title.png" />
        </div>
      </div>
    </header>
  );
};

export default Header;
